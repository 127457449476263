import React from "react"
import Seo from "../components/seo/Seo"

import Section from "../components/section/Section"
import SectionGrid from "../components/section/SectionGrid"
import SectionHeading from "../components/section/SectionHeading"
import SectionImage from "../components/section/SectionImage"
import SectionInfo from "../components/section/SectionInfo"
import SectionLink from "../components/section/SectionLink"
import SectionParagraph from "../components/section/SectionParagraph"

import karriere from "../assets/images/presentational/karriere.webp"
import SectionBanner from "../components/section/SectionBanner"

const Career = () => {
  return (
    <>
      <Seo
        title={"Karriere | Avalum"}
        description={
          "Avalum bietet spannende Jobs , die für Entspannung sorgen. Erfahre mehr über unsere offenen Stellen und über uns."
        }
      />
      <Section>
        <SectionGrid>
          <SectionImage src={karriere} />
          <SectionInfo>
            <SectionHeading>Karriere</SectionHeading>
            <SectionParagraph>
              Sie haben höchste Qualitätsansprüche und Freude an proaktiver und
              selbstständiger Arbeit?
            </SectionParagraph>
            <SectionParagraph>
              Dann haben wir das Richtige für Sie! Wir leben vom hohen
              Engagement, fachlichen Know-how und den persönlichen Fähigkeiten
              unserer Mitarbeiter.
            </SectionParagraph>
            <SectionParagraph>
              Erfahre mehr über unsere offenen Stellen und über uns.
            </SectionParagraph>
            <SectionParagraph>
              Wir freuen uns auf Ihre Bewerbung!
            </SectionParagraph>
            <SectionLink to={"/kontakt"} title={"Jetzt bewerben"} />
          </SectionInfo>
        </SectionGrid>
      </Section>
      <SectionBanner
        bg={karriere}
        to={"/kontakt"}
        title={"Bewerbe dich jetzt!"}
        heading={"Worauf wartest du noch?"}
      />
    </>
  )
}

export default Career
